AOS.init();

$(function() {
    var header = $("#header-main");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 1) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
    });
});

$('.product-slider-start').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    arrows: false,
    easing:'linear',
    autoplaySpeed: 0,
    speed:3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});

$('.image-slider-fullwidth').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: true,
    easing:'linear',
    centerMode: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
});

// Slider Product Detail

$('.prod-detail-top-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.prod-detail-top-slider-nav'
});

$('.prod-detail-top-slider-nav').slick({
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    asNavFor: '.prod-detail-top-slider',
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 639,
            settings: {
                slidesToShow: 2
            }
        }
    ]
});

// Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top - 90
                }, 1500);
            }
        }
    });

// OffCanvas

$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $(".nav-wrapper").toggleClass("active");
    $("body").toggleClass("no-scroll");

    offCanvasNavigation();
});

// Press "ESC"

$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".hamburger").removeClass("is-active");
        $(".nav-wrapper").removeClass("active");
        $("body").removeClass("no-scroll");
    }
});

function offCanvasNavigation() {
    var offCanvasItems = document.querySelectorAll('.nav-wrapper.active ul li');
    
    for (var i = 0; i < offCanvasItems.length; i++) {
        offCanvasItems[i].addEventListener('click', function() {
            this.classList.toggle('--active');
        });
    }
}